.Category-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    border-radius: 10px;
    margin: 19px auto;
    background: #e7e5e5;
}

.Category {
    display: flex;
    width: 75vw;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (min-width: 1050px)
.Category {
    max-width: 1290px;
}

@media (min-width: 992px)
.Category {
    max-width: 960px;
}
@media (min-width: 768px)
.Category {
    max-width: 720px;
}
@media (min-width: 576px)
.Category{
    max-width: 720px;
}
.Category {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 1200px)
.Category-xl, .Category-lg, .Category-md, .Category-sm, .Category {
    max-width: 1140px;
}
@media (min-width: 992px)
.Category-lg, .Category-md, .Category-sm, .Category {
    max-width: 960px;
}
@media (min-width: 768px)
.Category-md, .Category-sm, .Category {
    max-width: 720px;
}
@media (min-width: 576px)
.Category-sm, .Category {
    max-width: 540px;
}

.Categories-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    padding: 15px;
    margin: 20px auto;
    width: 70%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Categories{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.Category-Block{
    margin: 10px;
    height: 110px;
    width: 110px;
    border-radius: 10px;
    color: #333;
    font-size: 14px;
    padding: 10px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
}

.Category-Block:hover {
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
}

.Category-Block img {
    width: 50px;
    height: 50px;
}

.Load-More {
    background-color: #99c4da;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 4px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Load-More:hover {
    background-color: #6f98ad;
}