.Home-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-top: 30px;
    background: #e5feff;
}

.Home {
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.App-Container:hover {
    border-radius: 15px;
    background: #f6f6f6;
}

@media (min-width: 1050px)
.Home {
    max-width: 1290px;
}

@media (min-width: 992px)
.Home {
    max-width: 960px;
}
@media (min-width: 768px)
.Home {
    max-width: 720px;
}
@media (min-width: 576px)
.Home{
    max-width: 720px;
}
@media (min-width: 1200px)
.Home-xl, .Home-lg, .Home-md, .Home-sm, .Home {
    max-width: 1140px;
}
@media (min-width: 992px)
.Home-lg, .Home-md, .Home-sm, .Home {
    max-width: 960px;
}
@media (min-width: 768px)
.Home-md, .Home-sm, .Home {
    max-width: 720px;
}
@media (min-width: 576px)
    .Home-sm, .Home {
        max-width: 540px;
    }



.App-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1.5;
}

.App-Container h3 {
    margin-bottom: 0;
    font-size: 12px;
    text-align: left;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.App-Container img {
    width: 100px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.App-Container p {
    margin: 0;
    font-size: 13px;
    text-align: left;
    width: 110px;
}

.ant-menu {
    background-color: #0f172a;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    justify-content: space-between;
    height: 70px;
}

.ant-menu-item {
    order: 0 !important;
}

.ant-menu::before, .ant-menu::after {
    content: none;
}

.Footer {
    display: flex;
    font-family: "Urbanist", sans-serif;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
    background-color: #0f172a;
    bottom: 0;
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 6px;
    padding-top: 6px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.Footer-Logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Footer-Logo p{
    margin-right: 10px;
}

.Search-Result-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.Search-Container span{
    color: grey;
    font-size: 20px;
    font-width: bolder;
}

.Search-Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70vw;
    margin: auto;
}

.Footer-Links {
    display: flex;
    justify-content: space-between;
    width: 200px;
    padding: 0 10px;
    text-decoration: none;
}

.Footer-Links a {
    color: white;
    text-decoration: none;
    font-weight: bolder;
}
.Footer-Links a:visited {
    color: white;
}

.Load-More {
    background-color: #ffba80;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 4px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-family: "Urbanist", sans-serif;
}

.Load-More:hover {
    background-color: #0f172a;
}

    .area{
        position: relative;
        background: linear-gradient(145deg, #ffffff, #f0f0f0);
        background: -webkit-linear-gradient(to left, #ffffff, #f0f0f0);
        width: 70%;
        height:30vh;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        color: #0f172a;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .area span{
        z-index: 2000;
        font-size: 3em;
        font-weight: bold;
    }

    .area p{
        z-index: 2000;
        width:70%
    }

    .circles{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0;

    }

    .circles li{
        position: absolute;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        background: rgb(21, 35, 64);
        animation: animate 25s linear infinite;
        bottom: -150px;

    }

    .circles li:nth-child(1){
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
    }


    .circles li:nth-child(2){
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
    }

    .circles li:nth-child(3){
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
    }

    .circles li:nth-child(4){
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
    }

    .circles li:nth-child(5){
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
    }

    .circles li:nth-child(6){
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
    }

    .circles li:nth-child(7){
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
    }

    .circles li:nth-child(8){
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
    }

    .circles li:nth-child(9){
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
    }

    .circles li:nth-child(10){
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }



    @keyframes animate {
        0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
        }

        100% {
            transform: translateY(-120vh) rotate(720deg);
            opacity: 0;
            border-radius: 50%;
        }
    }

    @media (max-width: 1300px){
        .area span{
            font-size: 2em;
        }

        .area p{
            display: none;
        }
    }